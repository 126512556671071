import React from "react";
import { oneOfType, string, oneOf, arrayOf } from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import CaseItem from "./caseItem";

export const CaseList = ({ count, category, name }) => {
  const { cases } = useStaticQuery(graphql`
    query {
      cases: allMdx(
        sort: { fields: frontmatter___priority, order: ASC }
        filter: {
          fields: { collection: { eq: "cases" }, lang: { eq: "de" } }
          frontmatter: { inactive: { ne: true } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              category
              teaser
              description
              background
              logo {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const getFilteredCases = () => {
    if (category)
      return cases.edges.filter(({ node }) =>
        node.frontmatter.category.includes(category)
      );
    else if (name)
      return cases.edges.filter(({ node }) =>
        name.includes(node.frontmatter.slug)
      );
    return cases.edges;
  };

  const casesReduced = count
    ? getFilteredCases().slice(0, count)
    : getFilteredCases();

  return (
    <div className="row">
      {casesReduced.map(
        (
          {
            node: {
              frontmatter: { slug, title, logo, background, teaser },
            },
          },
          i
        ) => (
          <CaseItem
            teaser={teaser}
            image={logo.publicURL}
            backgroundColor={background}
            alt={title}
            linkTo={slug}
            key={i}
          />
        )
      )}
    </div>
  );
};

CaseList.propTypes = {
  count: string,
  category: oneOf([
    "coremedia",
    "firstspirit",
    "strapi",
    "formcentric",
    "frontend",
    "backend",
    "projectmanagement",
    "support",
    "design",
    "infrastructure",
    "strategy",
  ]),
  name: oneOfType([string, arrayOf(string)]),
};
